<template>
  <div class="saleReceiveQuantityStorage">
    <h1>仓储到货量统计</h1>
    <el-form :inline="true">
      <el-form-item label="卸车日期">
        <el-date-picker v-model="searchParam.receiveDate" type="date" style="width: 130px;" />
      </el-form-item>
      <el-form-item label="客户">
        <el-select v-model="searchParam.custom" filterable style="width: 120px;" clearable>
          <el-option v-for='customItem in show.customList' :key="customItem.id" :label="customItem.value" :value="customItem.value" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border :span-method="handleSpanMethod" show-summary :summary-method="summary" height="calc(100vh - 310px)">
      <el-table-column prop="storage" label="仓储" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.storage">{{ scope.row.storage }}</el-text>
          <el-text v-if="!scope.row.storage" style="font-weight: bolder;">合计</el-text>
        </template>
      </el-table-column>
      <el-table-column prop="sendDate" label="发货日期" show-overflow-tooltip />
      <el-table-column prop="customNote" label="销售品名" show-overflow-tooltip />
      <el-table-column prop="receiveQuantity" label="卸车数量" show-overflow-tooltip>
        <template #default="scope">
          <el-text v-if="scope.row.storage">{{ scope.row.receiveQuantity }}</el-text>
          <el-text v-if="!scope.row.storage" style="font-weight: bolder;">{{ scope.row.receiveQuantity }}</el-text>
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
  sumReceiveQuantity: 0,
})

const show = reactive({
  customList: [],
})

api.get('/backend/dict/getByCode', { params: { code: 'custom' } }).then(res => {
  show.customList = res.dictList
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.receiveDate = new Date()
  searchParam.custom = null
}
resetSearchParam()

const commitFindList = () => {
  const params = {}
  if (!searchParam.receiveDate) {
    ElMessage.error("请选择日期")
    return
  }
  params.receiveDate = util.parseTime(searchParam.receiveDate, '{y}-{m}-{d}')
  params.custom = searchParam.custom
  api.post('/backend/sale/saleReceiveQuantityStorage', params).then(res => {
    const list = []
    let sumAll = 0
    let sumStorage = {}
    for (const index in res.list) {
      const item = res.list[index]
      if (!sumStorage.storage) {
        sumStorage = {
          storage: item.storage,
          receiveQuantity: 0
        }
      }
      if (sumStorage.storage != item.storage) {
        list.push({
          receiveQuantity: sumStorage.receiveQuantity
        })
        sumStorage = {
          storage: item.storage,
          receiveQuantity: 0
        }
      }
      sumStorage.receiveQuantity = math.plus(sumStorage.receiveQuantity, item.receiveQuantity)
      sumAll = math.plus(sumAll, item.receiveQuantity)
      list.push(item)
    }
    if (list.size > 0) {
      list.push({
        receiveQuantity: sumStorage.receiveQuantity
      })
    }
    data.list = list
    data.sumReceiveQuantity = sumAll
  })
}

const summary = () => {
  return ['合计', '', '', data.sumReceiveQuantity]
}

const handleSpanMethod = ({ rowIndex, columnIndex }) => {
  const columnIndexs = [0] // 合并
  if (!columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  const row = data.list[rowIndex]
  if (rowIndex > 0) {
    const lastrow = data.list[rowIndex - 1]
    if (columnIndex == 0 && row.storage == lastrow.storage) {
      return [0, 0]
    }
  }
  let span
  for (span = 1; rowIndex + span < data.list.length; span++) {
    const nextrow = data.list[rowIndex + span]
    if (columnIndex == 0 && row.storage != nextrow.storage) {
      break
    }
  }
  return [span, 1]
}
</script>

<style lang="less"></style>